import React from 'react';
// interfaces
import { IImage } from '../../interfaces/image';
// components
import BackgroundImage from '../background-image';
import AddToBasket from '../add-to-basket/add-to-basket';
import Image from '@components/image/image';
// styles
import styles from './hero-fullscreen.module.scss';
// constants
interface IProps {
  title: string;
  description?: string;
  direction?: string;
  image?: IImage;
  backgroundImage?: IImage;
  backgroundColor?: string;
  loadingPlans?: boolean;
  plans?: any;
  bgDot?: boolean;
  identifier?: string;
  isAvailable?: boolean;
}

const HeroFullscreen: React.FC<IProps> = ({
  title,
  description,
  direction = '',
  image,
  backgroundColor = '',
  backgroundImage,
  loadingPlans,
  plans,
  bgDot = false,
  identifier = '',
  isAvailable = true,
}: IProps) => (
  <section className={`${styles['hero' + backgroundColor + direction]} ${bgDot ? styles.sectionBgDot : ''}`}>
    <div className={styles.container}>
      <div className={styles['row' + direction]}>
        {backgroundImage && <BackgroundImage image={backgroundImage} />}
        <div className={styles.textContainer}>
          <div className={styles.text}>
            {title && <h1>{title}</h1>}
            {title && <hr className={backgroundColor === '' ? styles.hrDark : styles.hr} />}
            {description && <p className={styles.subheading}>{description}</p>}
            {!loadingPlans && <AddToBasket plans={plans} isAvailable={isAvailable} />}
          </div>
        </div>
        {image && (
          <div className={styles.image}>
            <Image width={475} filename={image.filename_disk} alt={image.title} />
          </div>
        )}
      </div>
    </div>
  </section>
);

export default HeroFullscreen;
