import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroFullscreen from '../components/hero-fullscreen';
import Accordion from '../components/accordion';
import WomensAccordion from '../components/womens-accordion';
import Image from '@components/image/image';
import RecommendedProducts from '../components/recommended-products';
// services
import { trackProductViewed } from '../services/tracking/tracking';
// styles
import styles from './womens-health-product.module.scss';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';

export const data = graphql`
  query($path: String!) {
    directusProductTemplate2(path: { eq: $path }) {
      page_title
      page_description
      hero_image {
        filename_disk
        title
      }
      hero_description
      hero_title
      section_1_title
      section_1_description
      section_1_icons {
        title
        filename_disk
      }
      section_2_description
      section_3_description
      section_3_quality_items {
        title
        description
      }
      product {
        identifier
        pills {
          pill_image {
            filename_disk
          }
          name
          tagline
          description
          ingredients
          pill_form
          nutrients {
            name
            description
            image {
              filename_disk
              title
            }
            amount
            unit {
              short_name
            }
            nrv
          }
        }
      }
      recomended_products_items {
        title
        filename_disk
        button_label
        path
        identifier
        price
      }
    }
    directusProductTemplate2CommonElement {
      whats_inside_title
      why_youll_love_them_title
      faq_title
      section_quality_title
      section_quality_image {
        filename_disk
        title
      }
      nutritional_information_title
      recommended_products_title
    }
  }
`;

const WomensHealthProduct: React.FC<any> = ({
  data: {
    directusProductTemplate2: {
      page_title,
      page_description,
      hero_image,
      hero_description,
      hero_title,
      section_1_title,
      section_1_description,
      section_1_icons,
      section_2_description,
      section_3_description,
      section_3_quality_items,
      product,
      recomended_products_items,
    },
    directusProductTemplate2CommonElement: {
      whats_inside_title,
      why_youll_love_them_title,
      faq_title,
      section_quality_title,
      section_quality_image,
      nutritional_information_title,
      recommended_products_title,
    },
  },
}) => {
  const { dataPlans, loading: loadingPlans, isAvailable } = useGetProductPlansData(product.identifier);

  const accordionItems = section_3_quality_items.map((item: any) => ({
    question: item.title,
    answer: item.description,
  }));

  const accordionPillItems = product.pills.map((item: any) => ({
    question: (
      <div>
        {item?.pill_image?.filename_disk && (
          <div className={styles.pillImage}>
            <Image filename={item.pill_image.filename_disk} width={140} alt={item.name} />
          </div>
        )}
        <span className={styles.pillTitle}>{item.name}</span>
      </div>
    ),
    answer: (
      <div>
        <p className={styles.tagline}>
          <strong>{item.tagline}</strong>
        </p>
        <p>{item.description}</p>
      </div>
    ),
  }));

  const nutritionalInfoItems = product.pills.map((item: any) => ({
    question: (
      <div>
        {item?.pill_image?.filename_disk && (
          <img
            className={styles.nutritionalPillImage}
            src={`${process.env.GATSBY_MEDIA_URL}${item.pill_image.filename_disk}?w=90`}
            alt=""
          />
        )}
        <h4 className={styles.nutritionalPillTitle}>{item.name}</h4>
      </div>
    ),
    answer: (
      <div className={styles.nutritionWrapper}>
        <p>{item.description}</p>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td className={styles.alignRight}>{`Per ${item.pill_form}`}</td>
              <td className={styles.alignRight}>% NRV</td>
            </tr>
            {item.nutrients.map((nutrient: any, index: number) => (
              <tr key={index}>
                <td>{nutrient.name}</td>
                <td className={styles.alignRight}>
                  {`${nutrient.amount > 1000000 ? (nutrient.amount / 1000000000).toFixed(1) : nutrient.amount} ${
                    nutrient.unit?.short_name || ' billion'
                  }`}
                </td>
                <td className={styles.alignRight}>{nutrient.nrv ? nutrient.nrv : ' † '}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>† NRV not established</p>
        <div dangerouslySetInnerHTML={{ __html: item.ingredients }} />
      </div>
    ),
  }));

  const location = useLocation();

  // tracking
  useEffect(() => {
    hero_title.includes('pack')
      ? trackProductViewed({
          name: hero_title,
          url: location.href,
          product_id: product?.identifier,
          category: "women's health",
        })
      : trackProductViewed({
          name: hero_title,
          url: location.href,
          product_id: product?.identifier,
          category: 'tins',
        });
  }, []);

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />
      <HeroFullscreen
        title={hero_title}
        image={hero_image}
        backgroundColor="PrimaryLighter"
        description={hero_description}
        direction="Right"
        loadingPlans={loadingPlans}
        plans={dataPlans ? dataPlans.product_plans : []}
        bgDot
        identifier={product.identifier}
        isAvailable={isAvailable}
      />

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{section_1_title}</h2>
              <hr className={styles.hrLeftAligned} />
              <div dangerouslySetInnerHTML={{ __html: section_1_description }} />
            </div>
            <div className={styles.iconsContainer}>
              <ul className={styles.icons}>
                {section_1_icons.map((icon: any, index: number) => (
                  <li key={index} className={styles.icon}>
                    <div>
                      <Image filename={icon.filename_disk} width={45} alt={icon.title} />
                    </div>
                    <span>{icon.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {product.pills.length > 1 ? (
        <section className={styles.sectionPills}>
          <div className={styles.containerPills}>
            <div className={styles.row}>
              <div className={styles.contentCentered}>
                <h2>{whats_inside_title}</h2>
                <hr className={styles.hrDark} />
                <p dangerouslySetInnerHTML={{ __html: section_2_description }} />
              </div>
            </div>
            <div className={styles.rowMobile}>
              <div className={styles.contentCentered}>
                <Accordion items={accordionPillItems} isTransparent={true} />
              </div>
            </div>
          </div>

          <div className={styles.desktopPills}>
            {accordionPillItems.map((pill: any, index: number) => (
              <div className={styles.desktopPill} key={index}>
                <div className={styles.desktopPillTitle}>{pill.question}</div>
                <div className={styles.desktopPillDescription}>{pill.answer}</div>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <section className={styles.sectionPrimary}>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.content}>
                <h2>{product.identifier === 'cbd-gummies-tin' ? why_youll_love_them_title : whats_inside_title}</h2>
                <hr className={styles.hrDarkLeftAligned} />
                <div dangerouslySetInnerHTML={{ __html: section_2_description }} />
              </div>
              <div className={styles.contentTextCentered}>
                {product.pills[0]?.pill_image?.filename_disk && (
                  <Image
                    filename={product.pills[0].pill_image.filename_disk}
                    width={140}
                    alt={accordionPillItems[0].name}
                  />
                )}
                <h4>{product.pills[0].name}</h4>
                <hr className={styles.hrDark} />
                <p>
                  <strong>{product.pills[0].tagline}</strong>
                  <br />
                  {product.pills[0].description}
                </p>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className={styles.sectionQuality}>
        <div className={styles.qualityContainer}>
          <div className={styles.rowCentered}>
            <div className={styles.image}>
              <Image filename={section_quality_image.filename_disk} width={640} />
            </div>
            <div className={styles.contentQuality}>
              <h2>{product.identifier === 'cbd-gummies-tin' ? faq_title : section_quality_title}</h2>
              <hr className={styles.hrLeftAligned} />
              <div dangerouslySetInnerHTML={{ __html: section_3_description }} />
              <WomensAccordion items={accordionItems} />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentNutritionalInfo}>
              <h2>{nutritional_information_title}</h2>
              <hr />

              <WomensAccordion
                isTransparent={true}
                openIfSingleItem={true}
                isLargeRow={true}
                items={nutritionalInfoItems}
              />
            </div>
          </div>
        </div>
      </section>
      {recomended_products_items && (
        <RecommendedProducts title={recommended_products_title} items={recomended_products_items} />
      )}
    </Layout>
  );
};

export default WomensHealthProduct;
