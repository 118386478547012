import React, { useState } from 'react';
// styles
import styles from './womens-accordion.module.scss';

interface IProps {
  items: Array<any>;
  isTransparent?: boolean;
  isHtmlContent?: boolean;
  openIfSingleItem?: boolean;
  isLargeRow?: boolean;
}

const WomensAccordion: React.FC<IProps> = ({
  items,
  isTransparent = false,
  openIfSingleItem = false,
  isHtmlContent = false,
  isLargeRow = false,
}) => {
  const active = items.length === 1 && openIfSingleItem ? 0 : -1;
  const [activeItemIndex, setActiveItemIndex] = useState(active);
  const itemStyle = isLargeRow ? styles.itemLarge : styles.item;
  const itemActiveStyle = isLargeRow ? styles.itemActiveLarge : styles.itemActive;

  return (
    <ul className={isTransparent ? styles.listTransparent : itemStyle}>
      {items.map((item: any, i: number) => (
        <li className={i === activeItemIndex ? itemActiveStyle : itemStyle} key={i}>
          <div className={styles.itemHeader} onClick={() => setActiveItemIndex(activeItemIndex !== i ? i : -1)}>
            {item.question}
          </div>
          {isHtmlContent ? (
            <div className={styles.itemBody} dangerouslySetInnerHTML={{ __html: item.answer }} />
          ) : (
            <div className={styles.itemBody}>{item.answer}</div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default WomensAccordion;
