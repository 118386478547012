import React from 'react';
// interfaces
import { IImage } from '../../interfaces/image';
import Image from '@components/image/image';
// styles
import styles from './background-image.module.scss';

interface IProps {
  image: IImage;
}

const BackgroundImage: React.FC<IProps> = ({ image }: IProps) => {
  return <Image noBlurLoading className={styles.backgroundImage} filename={image.filename_disk} />;
};

export default BackgroundImage;
